import React, { useState, useEffect } from 'react';
import CoinModal from './coin-modal/CoinModal';
import {
  useGetWalletBalanceQuery,
  useGetWalletBalanceLazyQuery,
} from '@src/components/generated/graphql';
import { Modal } from 'antd';
import { useUserScenesQuery } from '@src/components/generated/graphql';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import styles from './coins-widget.module.css';

export default function CoinWidget({ isSignIn, userId, sceneId }) {
  const [open, setOpen] = useState(false);
  const [isGame, setIsGame] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [fcBalance, setFcBalance] = useState(0);
  const [startMatchCheckModal, setStartMatchCheckModal] = useState(false);
  const [getBalance] = useGetWalletBalanceLazyQuery();

  const { data } = useUserScenesQuery({
    variables: {
      where: {
        id: {
          equals: sceneId as any,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { refetch } = useGetWalletBalanceQuery({
    variables: {
      data: {
        userId,
      },
    },
    onCompleted: (data) => {
      setFcBalance(data.getWalletBalance.balance);
    },
  });

  const scene = data?.userScenes[0];

  async function onGetFcBalance() {
    const res = await getBalance({
      variables: {
        data: {
          userId,
        },
      },
    });
    window.dispatchEvent(
      new CustomEvent('set_fc_balance', {
        detail: { balance: res?.data?.getWalletBalance.balance },
      })
    );
  }

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  function onHideUI() {
    setIsVisible(false);
  }

  function onShowUI() {
    setIsVisible(true);
  }

  function onRefetch() {
    refetch();
  }

  function closeCheck() {
    setOpen(true);
    setStartMatchCheckModal(false);
  }

  function onShowCheck() {
    setStartMatchCheckModal(true);
  }

  function onShowExchange() {
    setOpen(true);
  }

  useEffect(() => {
    if (scene?.gameMode) {
      setIsGame(true);
    }
  }, [scene]);

  useEffect(() => {
    window.addEventListener('hide_ui_widgets', onHideUI);
    window.addEventListener('update_ui_queries', onRefetch);
    window.addEventListener('show_ui_widgets', onShowUI);
    window.addEventListener('not_enough_tokens', onShowCheck);
    window.addEventListener('get_fc_balance', onGetFcBalance);
    window.addEventListener('open_exchange_token', onShowExchange);

    return () => {
      window.removeEventListener('hide_ui_widgets', onHideUI);
      window.removeEventListener('show_ui_widgets', onShowUI);
      window.removeEventListener('get_fc_balance', onGetFcBalance);
      window.removeEventListener('update_ui_queries', onRefetch);
      window.removeEventListener('not_enough_tokens', onShowCheck);
      window.removeEventListener('open_exchange_token', onShowExchange);
    };
  }, []);

  if (!isSignIn || !isGame || !isVisible) {
    return <></>;
  }

  return (
    <>
      <div className={styles.root}>
        <CoinModal
          open={open}
          closeModal={closeModal}
          userId={userId}
          fcBalance={fcBalance}
        />
        <Modal
          title='Not enough $COINS'
          open={startMatchCheckModal}
          onCancel={closeCheck}
          footer={false}
        >
          <div className={styles.startCheckRoot}>
            <div className={styles.startCheckText}>
              <span>
                You need to exchange more $COINS to start playing game. Minimum
                required 25 $COINS to start play.
              </span>
            </div>
            <DefaultButton type='primary' onClick={closeCheck}>
              <span style={{ color: '#fff' }}>Exchange FC</span>
            </DefaultButton>
          </div>
        </Modal>
      </div>
    </>
  );
}
