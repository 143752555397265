import { useEffect, useState } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';

export function useMetamask(): any {
  const initialState = null;
  const [hasMetamaskProvider, setHasMetamaskProvider] = useState<
    boolean | null
  >(null);
  const [walletMetamask, setWalletMetamask] = useState(initialState as any);

  function updateWallet(account: any) {
    setWalletMetamask(account);
  }

  function logout() {
    updateWallet(initialState);
  }

  async function login() {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    if (!accounts.length) {
      return;
    }

    updateWallet(accounts[0]);
  }

  useEffect(() => {
    const refreshAccounts = (accounts: any) => {
      if (accounts.length > 0) {
        updateWallet(accounts[0]);
      } else {
        updateWallet(initialState);
      }
    };

    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true });
      setHasMetamaskProvider(Boolean(provider));

      if (provider) {
        const accounts = await window.ethereum.request({
          method: 'eth_accounts',
        });
        refreshAccounts(accounts);
        window.ethereum.on('accountsChanged', refreshAccounts);
      }
    };

    getProvider();

    return () => {
      window.ethereum?.removeListener('accountsChanged', refreshAccounts);
    };
  }, []);

  return [walletMetamask, hasMetamaskProvider, login, logout];
}
