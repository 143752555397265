import { useEffect, useState } from 'react';
import {
  Modal,
  Avatar,
  Typography,
  Flex,
  Divider,
  InputNumber,
  Form,
  Button,
  Spin,
} from 'antd';
import {
  useDepositFcMutation,
  useConvertOzoneToFcMutation,
  GetWalletBalanceDocument,
} from '@src/components/generated/graphql';
import { openNotification } from 'util/helpers';
import { LoadingOutlined } from '@ant-design/icons';
import OzoneToken from '@services/OzoneToken';
import { useMetamask } from '@src/components/hooks/useMetamask';
import metamaskStyles from '../../../side-bar-menu/quests/complete-quest-modal.module.css';
import styles from './styles.module.css';

const error = 'Something went wrong';
const success = 'Successfully exchanged tokens!';

export default function CoinModal({ open, closeModal, userId, fcBalance }) {
  const [walletMetamask, hasMetamaskProvider, login, logout] = useMetamask();
  const [loading, setLoading] = useState(false);
  const [updateOzoneBalance, setUpdateOzoneBalance] = useState(false);
  const [ozoneBalance, setOzoneBalance] = useState('0');
  const [depositFC] = useDepositFcMutation();
  const [convert] = useConvertOzoneToFcMutation();

  function toFixedBalance(balance: string) {
    return parseInt(balance).toFixed(2);
  }

  async function onFinish(values) {
    setLoading(true);
    const ozoneAmount = values.amount;

    const res = await convert({
      variables: {
        where: {
          price: ozoneAmount,
        },
      },
    });

    if (
      !res.data?.convertOzoneToFC.status ||
      !res.data?.convertOzoneToFC.convertedPrice
    ) {
      setLoading(false);
      openNotification('Error', error, 'top', 2, 'error');
      return;
    }

    const bnbTx = await OzoneToken.withdraw(
      res.data?.convertOzoneToFC.convertedPrice
    );

    setUpdateOzoneBalance(true);

    if (!bnbTx || !bnbTx?.receipt || bnbTx.error) {
      setLoading(false);
      openNotification('Error', error, 'top', 2, 'error');
      return;
    }

    const deposit = await depositFC({
      variables: {
        data: {
          amount: res.data?.convertOzoneToFC.convertedPrice,
          userId,
        },
      },
      refetchQueries: [GetWalletBalanceDocument],
    });

    if (!deposit.data?.depositFC.status) {
      setLoading(false);
      openNotification('Error', error, 'top', 2, 'error');
      return;
    }

    openNotification('Success', success, 'top', 2, 'success');
    setUpdateOzoneBalance(false);
    setLoading(false);
  }

  useEffect(() => {
    async function fetch() {
      if (!walletMetamask) {
        return;
      }
      const balance = await OzoneToken.baseTokenBalance(walletMetamask);
      if (balance.error || !balance.receipt) {
        setOzoneBalance('ERROR');
        return;
      }
      setOzoneBalance(toFixedBalance(balance.receipt!));
    }

    fetch();
  }, [walletMetamask, updateOzoneBalance]);

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      footer={false}
      title='Fight Coin'
      destroyOnClose
    >
      <Flex className={styles.root} vertical gap={15}>
        <div className={styles.balanceBlock}>
          <Typography.Title level={4} style={{ marginBottom: 5 }}>
            Your $COINS balance:
          </Typography.Title>
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            {fcBalance} FC
          </Typography.Title>
        </div>
        <div>
          <Divider />
          <Typography.Title level={4}>
            Exchange $OZONE token to $COINS
          </Typography.Title>
          {!walletMetamask ? (
            <>
              <Typography.Title level={5}>
                Login with your Metamask
              </Typography.Title>
              {hasMetamaskProvider && (
                <div
                  className={metamaskStyles.walletWrapper}
                  onClick={login as any}
                >
                  <Avatar
                    src={'/metamask-logo.png'}
                    className={metamaskStyles.iconWallet}
                  ></Avatar>
                  <span className={metamaskStyles.titleWallet}>Metamask</span>
                </div>
              )}
              {!hasMetamaskProvider && (
                <div className={metamaskStyles.noMetamaskProvider}>
                  <p className={metamaskStyles.noMetamaskText}>
                    If you want to use your
                    <a
                      href='https://metamask.io/download/'
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        display: 'inline',
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                      className={metamaskStyles.noMetamaskLink}
                    >
                      Metamask
                    </a>
                    wallet, you need to install browser extension.
                  </p>
                  <a
                    href='https://metamask.io/download/'
                    target='_blank'
                    rel='noreferrer'
                    className={metamaskStyles.noMetamaskLink}
                  >
                    <Avatar
                      src={'/metamask-logo.png'}
                      className={metamaskStyles.iconWallet}
                    ></Avatar>
                    <span className={metamaskStyles.titleWallet}>
                      Download ↗
                    </span>
                  </a>
                </div>
              )}
            </>
          ) : (
            <>
              <div>
                <div className={styles.balanceBlock}>
                  <Typography.Title level={4} style={{ marginBottom: 5 }}>
                    Your $OZONE balance:
                  </Typography.Title>
                  <Typography.Title level={3} style={{ marginTop: 0 }}>
                    {ozoneBalance} OZONE
                  </Typography.Title>
                  <Button type='primary' onClick={logout}>
                    Change Wallet
                  </Button>
                </div>
                <Form
                  layout='vertical'
                  className={styles.form}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name='amount'
                    label='$COINS'
                    help='min: 1 max: 2500. 1 $COINS = 10 $OZONE'
                    rules={[
                      {
                        required: true,
                        message: 'Please specify the number of $COINS.',
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={2500}
                      precision={0}
                      controls={false}
                      placeholder='1'
                      className={styles.inputNumber}
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={loading}
                    className={styles.submitButton}
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: '#000' }}
                            spin
                          />
                        }
                      ></Spin>
                    ) : (
                      <span>Exchange</span>
                    )}
                  </Button>
                </Form>
              </div>
            </>
          )}
        </div>
      </Flex>
    </Modal>
  );
}
