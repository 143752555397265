import { useEffect, useState } from 'react';
import { Button, Flex, Modal } from 'antd';
import { useSession } from 'next-auth/react';
import {
  useFindMatchesByUserIdQuery,
  useGetAssetsWeb2LazyQuery,
  GetAssetsWeb2Document,
  GetBoughtAssetsByUserDocument,
  useBuyAssetWeb2FcMutation,
  useGetBoughtAssetsByUserLazyQuery,
  useWearSkinMutation,
} from '@src/components/generated/graphql';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import { SIGN_IN_URL_NO_DEFAULT_REDIRECT } from 'util/constants';
import { openNotification } from 'util/helpers';
import styles from './game-widget.module.css';

export default function GameWidget({ userId }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [skinsToChange, setSkinsToChange] = useState<any>([]);
  const [openSkinsChange, setOpenSkinsChange] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedSkin, setSelectedSkin] = useState<any>(null);
  const { data: session } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [multiSession, setMultiSession] = useState(false);
  const [lobbyConnected, setIsLobbyConnected] = useState(false);
  const [getSkins] = useGetAssetsWeb2LazyQuery();
  const [buySkin] = useBuyAssetWeb2FcMutation();
  const [getBoughtSkins] = useGetBoughtAssetsByUserLazyQuery();
  const [wearSkin] = useWearSkinMutation();
  const [skinId, setSkinId] = useState('');
  let cachedSkinName = '';

  const { data, refetch } = useFindMatchesByUserIdQuery({
    variables: {
      where: {
        userId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  async function onGetBoughtSkins() {
    const res = await getBoughtSkins({
      variables: {
        where: {
          userId: session?.user.id!,
          offset: 0,
          limit: 100,
          filter: {
            type: 'all',
            searchInput: 'skins',
          },
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (!res || res.error) {
      console.error(`Error during fetching bought skins.`);
      return;
    }

    window.dispatchEvent(
      new CustomEvent('set_bought_skins', {
        detail: { data: res.data?.getBoughtAssetsByUser.assets },
      })
    );
  }

  async function onGetAvatarSkins(event) {
    const { avatar } = event.detail;

    if (!avatar) {
      console.error('Error during fetching skins, missing avatar name.');
      return;
    }

    const res = await getSkins({
      variables: {
        where: {
          offset: 0,
          limit: 10,
          filter: {
            type: 'all',
            arrayTagsSearch: ['skins', avatar.toLowerCase()],
          },
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (!res || res.error) {
      console.error(`Error during fetching skins for avatar ${avatar}`);
      return;
    }

    window.dispatchEvent(
      new CustomEvent('set_avatar_skins', {
        detail: { data: res.data?.getAssetsWeb2.assets },
      })
    );
  }

  function login() {
    window.location.assign(
      `${SIGN_IN_URL_NO_DEFAULT_REDIRECT}${encodeURIComponent(
        window.location.href
      )}`
    );
  }

  async function connectLobby() {
    if (lobbyConnected) {
      return;
    }

    let token;

    try {
      const res = await fetch(`/api/auth/token`).then((res) => res.json());
      token = res.token;
    } catch (e) {
      console.error(e);
    }

    if (!token) {
      console.log('Cant get user session token');
      return;
    }

    window.dispatchEvent(
      new CustomEvent('connect_lobby', { detail: { token } })
    );

    setIsLobbyConnected(true);
  }

  function onRefetch() {
    refetch();
  }

  function onMultiSession() {
    setMultiSession(true);
  }

  function onGetProfile() {
    window.dispatchEvent(
      new CustomEvent('set_profile', {
        detail: {
          profile: {
            email: session?.user.email,
            avatar: session?.user.avatarUrl,
          },
        },
      })
    );
  }

  const handleCancel = () => {
    setOpenConfirm(false);
  };

  async function buy() {
    setConfirmLoading(true);
    const res = await buySkin({
      variables: {
        data: {
          assetId: skinId,
          userId: session?.user.id!,
        },
      },
      refetchQueries: [GetAssetsWeb2Document, GetBoughtAssetsByUserDocument],
    });

    if (!res || res.errors || !res.data?.buyAssetWeb2FC.status) {
      openNotification(
        'Error',
        res.data?.buyAssetWeb2FC.message ??
          'Error occured, please contact Ozone',
        'topRight',
        3,
        'error'
      );
      return;
    }

    openNotification(
      'Success',
      'Successfully bought new skin',
      'topRight',
      3,
      'success'
    );
    setOpenConfirm(false);
    setConfirmLoading(false);
  }

  async function onBuySkin(event) {
    const { skinId } = event.detail;

    if (!skinId) {
      console.error('Error during buy skin, missing skin ID.');
      return;
    }

    setOpenConfirm(true);
    setSkinId(skinId);
  }

  useEffect(() => {
    if (!userId) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [userId]);

  async function onOpenChangeSkin(event) {
    const { skin } = event.detail;

    if (!skin) {
      console.error('Error during fetching skins, missing skin name.');
      return;
    }

    if (cachedSkinName === skin) {
      setOpenSkinsChange(true);
      return;
    }

    cachedSkinName = skin;

    const res = await getBoughtSkins({
      variables: {
        where: {
          userId: session?.user.id!,
          offset: 0,
          limit: 100,
          filter: {
            type: 'all',
            searchInput: 'skins',
          },
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (!res || res.error) {
      openNotification(
        'Error',
        'Error while loading skins',
        'topRight',
        3,
        'error'
      );
      return;
    }

    setOpenSkinsChange(true);
    setSkinsToChange(
      res.data?.getBoughtAssetsByUser.assets?.filter((asset) =>
        asset.tag.includes(skin.toLowerCase())
      ) as any
    );
  }

  function onClose() {
    setOpenSkinsChange(false);
    setSelectedSkin(null);
  }

  async function selectSkin() {
    const res = await wearSkin({
      variables: {
        where: {
          userId: session?.user.id!,
          assetId: selectedSkin.id,
        },
      },
    });

    if (!res || res.errors) {
      openNotification(
        'Error',
        'Error while select skins',
        'topRight',
        3,
        'error'
      );
      return;
    }

    setOpenSkinsChange(false);
    setSelectedSkin(null);
  }

  useEffect(() => {
    window.addEventListener('ui_events_set', connectLobby);
    window.addEventListener('open_change_item_skin', onOpenChangeSkin);
    window.addEventListener('update_ui_queries', onRefetch);
    window.addEventListener('get_profile', onGetProfile);
    window.addEventListener('buy_skin', onBuySkin);
    window.addEventListener('user_already_connected_to_lobby', onMultiSession);
    window.addEventListener('get_avatar_skins', onGetAvatarSkins);
    window.addEventListener('get_bought_skins', onGetBoughtSkins);

    return () => {
      window.removeEventListener('ui_events_set', connectLobby);
      window.removeEventListener('update_ui_queries', onRefetch);
      window.removeEventListener('open_change_item_skin', onOpenChangeSkin);
      window.removeEventListener('get_profile', onGetProfile);
      window.removeEventListener('buy_skin', onBuySkin);
      window.removeEventListener('get_avatar_skins', onGetAvatarSkins);
      window.removeEventListener('get_bought_skins', onGetBoughtSkins);
      window.removeEventListener(
        'user_already_connected_to_lobby',
        onMultiSession
      );
    };
  }, []);

  if (!isLoggedIn) {
    return (
      <div className={styles.root}>
        <span>You need to log in to your Ozone account to play the Game.</span>
        <DefaultButton type='primary' onClick={login}>
          <span style={{ color: '#fff' }}>Sign in</span>
        </DefaultButton>
      </div>
    );
  }

  if (multiSession) {
    return (
      <div className={styles.root}>
        <span>
          You already have game opened in other tab, please close other game
          instances before open new.
        </span>
      </div>
    );
  }

  return (
    <div className={styles.game}>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        title='Matches'
        footer={false}
      >
        <div className={styles.matches}>
          {data?.findMatchesByUserId?.matches?.map((match, i) => {
            const date = new Date(match.createdAt).toLocaleDateString();

            return (
              <div key={i} className={styles.match}>
                {match.winner === userId ? (
                  <span className={styles.win}>WIN</span>
                ) : !match?.winner ? (
                  <span className={styles.loss}>CANCELED</span>
                ) : (
                  <span className={styles.loss}>LOSS</span>
                )}
                {match.reward && (
                  <span>
                    {match.winner === userId
                      ? `+ ${match.reward} FC`
                      : `- ${match.reward} FC`}
                  </span>
                )}
                <span>{date}</span>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        title='Buy skin'
        open={openConfirm}
        onOk={buy}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to buy this skin?</p>
      </Modal>
      <Modal
        open={openSkinsChange}
        styles={{ body: { color: '#fff' } }}
        onCancel={onClose}
        footer={false}
        centered
        width={1000}
        className={'modal-black'}
        title='Choose a skin'
        mask={false}
      >
        <Flex vertical>
          <Flex gap={24} style={{ marginTop: 24 }}>
            {skinsToChange.length ? (
              skinsToChange.map((skin) => {
                return (
                  <div
                    key={skin.id}
                    className={
                      selectedSkin?.id === skin.id
                        ? styles.selectedCard
                        : styles.card
                    }
                    onClick={() => setSelectedSkin(skin)}
                  >
                    <img src={skin.previewImage} alt='' />
                  </div>
                );
              })
            ) : (
              <Flex align='center' className={styles.empty}>
                You don't have skins yet. You can go to the shop to get some
                skins.
              </Flex>
            )}
          </Flex>
          <Flex justify='end' gap={24} style={{ marginTop: 24 }}>
            {selectedSkin && (
              <>
                <Button className={styles.cancel} onClick={onClose}>
                  CANCEL
                </Button>
                <Button className={styles.ok} onClick={selectSkin}>
                  SELECT
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Modal>
      <style>{`
          .public-scene-auth {
            display: none;
          }
    `}</style>
    </div>
  );
}
