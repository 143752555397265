import React, { useEffect, useState } from 'react';
import { Button, Flex, Modal } from 'antd';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import styles from './tour-widget.module.css';
import { useFindManyUserSceneAssetsQuery } from '@ozoneuniverse/ui-components/build-pkg/src/components/generated/graphql';
import dynamic from 'next/dynamic';

const ModelViewer = dynamic(
  () => import('../../side-bar-menu/my-stuff/upload-asset/ModelViewer'),
  { ssr: false }
);

const TourWidget = ({ sceneId }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({} as any);

  const { data: assets, loading } = useFindManyUserSceneAssetsQuery({
    variables: {
      where: {
        userSceneId: {
          equals: sceneId,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const next = () => {
    window.dispatchEvent(new CustomEvent('tour_next_item'));
  };
  const start = () => {
    window.dispatchEvent(new CustomEvent('start_tour'));
  };
  const prev = () => {
    window.dispatchEvent(new CustomEvent('tour_prev_item'));
  };
  const exit = () => {
    window.dispatchEvent(new CustomEvent('exit_tour'));
    setOpen(false);
  };

  const onOpenModal = (e) => {
    setItem(e.detail.item);
    setOpen(true);
  };

  const hasTourElement = () => {
    return assets?.findManyUserSceneAssets.some((asset) => {
      try {
        const json = JSON.parse(asset.metadata || '');
        if (json.poiData?.isTour) {
          return asset;
        }

        return undefined;
      } catch (e) {
        console.log('tour asset: invalid JSON structure');
        return undefined;
      }
    });
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('open_tour_modal', onOpenModal);
    }

    return () => {
      window.removeEventListener('open_tour_modal', onOpenModal);
    };
  }, []);

  if (loading || !hasTourElement()) {
    return <></>;
  }

  return (
    <div className={styles.root}>
      <Button onClick={start} type='primary'>
        Start tour
      </Button>
      <Modal
        open={open}
        onCancel={exit}
        footer={false}
        centered={false}
        style={{ left: '-30%', top: '5%' }}
        mask={false}
      >
        <Flex>
          <Flex vertical style={{ flex: 1 }}>
            <h2 className={styles.title}>{item.title}</h2>
            <p>{item.description}</p>
          </Flex>
          <ModelViewer src={item.url} height={200} />
        </Flex>

        <div className={styles.buttons}>
          <div className={styles.slidesButtons}>
            <DefaultButton onClick={prev} type='primary'>
              <span style={{ color: '#fff' }}>Back</span>
            </DefaultButton>
            <DefaultButton
              onClick={next}
              style={{ marginLeft: 10 }}
              type='primary'
            >
              <span style={{ color: '#fff' }}>Next</span>
            </DefaultButton>
          </div>
          <DefaultButton onClick={exit} type='primary'>
            <span style={{ color: '#fff' }}>Exit</span>
          </DefaultButton>
        </div>
      </Modal>
    </div>
  );
};

export default TourWidget;
