import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { unstable_getServerSession } from 'next-auth/next';
import { FindAliasSceneDocument } from '@src/components/generated/graphql';
import { authOptions } from 'pages/api/auth/[...nextauth]';
import { useWindowSize } from '@src/components/hooks/useWindowDimensions';
import { initializeApollo } from '../../src/apolloClient';
import { SIGN_IN_URL_NO_DEFAULT_REDIRECT } from 'util/constants';
import GameWidget from '@src/components/scene/widgets/GameWidget';
import LandingGame from '@ozoneuniverse/canvas-components/dist/src/components/main-menu-fight-game/landing';
import QuestSystemWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/QuestSystemWidget';
import CheckoutWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/CheckoutWidget';
import NftAvatarsWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/NftAvatarsWidget';
import LinkAssetWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/LinkAssetWidget';
import PortalWidget from '@src/components/scene/widgets/PortalWidget';
import CoinWidget from '@src/components/scene/widgets/CoinsWidget';
import TourWidget from '@src/components/scene/widgets/TourWidget';
import ChatWidget from '@src/components/scene/chat/ChatWidget';
import dynamic from 'next/dynamic';
import ScanWidget from '@src/components/scene/scan/ScanWidget';

const client = initializeApollo();

export async function getServerSideProps(context) {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  );

  console.log('session?.user.email', session?.user);
  console.log('session?.user.email', session);

  const scene = await client.query({
    query: FindAliasSceneDocument,
    variables: { where: { alias: context.query.id } },
    fetchPolicy: 'no-cache',
  });

  let sceneId;

  if (scene.data.findAliasScene.exists) {
    sceneId = scene.data.findAliasScene.sceneId;
  } else {
    sceneId = context.query.id;
  }

  return {
    props: {
      isSignIn: session ? true : false,
      userId: session?.user.id ?? '',
      userEmail: session?.user.email ?? '',
      sceneId,
    },
  };
}

const ComponentWithNoSSR = dynamic(
  () =>
    import(
      '@ozoneuniverse/ui-components/build-pkg/src/components/scene/publicScene'
    ),
  {
    ssr: false,
  }
);
// we need this component to prevent re-render full app cause of the size hook
const SizeChecker = ({ isMobile, setIsMobile }) => {
  const size = useWindowSize();

  useEffect(() => {
    if (!isMobile && size?.width! <= 700) {
      setIsMobile(true);
    }

    if (isMobile && size?.width! > 700) {
      setIsMobile(false);
    }
  }, [size.width, isMobile]);
  return <></>;
};

export function Scene(props) {
  const [isMobile, setIsMobile] = useState(false);

  function onNeedAuth() {
    window.location.assign(
      `${SIGN_IN_URL_NO_DEFAULT_REDIRECT}${encodeURIComponent(
        window.location.href
      )}`
    );
  }

  useEffect(() => {
    window.addEventListener('user_need_auth', onNeedAuth);

    return () => {
      window.removeEventListener('user_need_auth', onNeedAuth);
    };
  }, []);

  if (!props.isSignIn || isMobile) {
    return (
      <div style={{ marginTop: -70 }}>
        <SizeChecker setIsMobile={setIsMobile} isMobile={isMobile} />
        <LandingGame isSignIn={props.isSignIn} />
      </div>
    );
  }

  return (
    <>
      <div id='local-media'></div>
      <div id='webrtc'>
        <div id='participants'>
          <div id='remote-media'></div>
        </div>
        <div id='room-controls'>
          <button id='button-join'>Join Room</button>
          <button id='button-leave'>Leave Room</button>
          <button id='sound-track'>Mute</button>
          <button id='video-track'>Disable video</button>
          <input id='upload-image' type='file' />
        </div>

        <button id='start_share'>Start Capture</button>
        <button id='stop_share'>Stop Capture</button>

        <video id='video_share' autoPlay></video>
        <strong>Log:</strong>
        <br />
        <pre id='log'></pre>
      </div>
      <SizeChecker setIsMobile={setIsMobile} isMobile={isMobile} />
      <ComponentWithNoSSR
        userId={props.userId}
        userEmail={props.userEmail}
        isSignIn={props.isSignIn}
        sceneId={props.sceneId}
      >
        <CheckoutWidget />
        <QuestSystemWidget />
        <PortalWidget />
        <NftAvatarsWidget />
        <LinkAssetWidget />
        <GameWidget userId={props.userId} />
        <CoinWidget
          sceneId={props.sceneId}
          isSignIn={props.isSignIn}
          userId={props.userId}
        />
        <ChatWidget sceneId={props.sceneId} />
        <ScanWidget sceneId={props.sceneId} />
        <TourWidget sceneId={props.sceneId} />
      </ComponentWithNoSSR>
    </>
  );
}
export default Scene;
