import { ethers } from 'ethers';
import tokenAbi from './tokenAbi.json';

export class bnbStakingService {
  bscProvider: ethers.providers.BaseProvider;
  ozoneTokenContract: ethers.Contract;
  gasLimit: number;

  constructor() {
    this.bscProvider = ethers.getDefaultProvider(
      process.env.NEXT_PUBLIC_GETBLOCK_API_KEY
    );

    this.ozoneTokenContract = new ethers.Contract(
      process.env.NEXT_PUBLIC_BASE_TOKEN_CONTRACT_ADDRESS!,
      tokenAbi,
      this.bscProvider
    );

    this.gasLimit = 200000; // ≈0.3724 $
  }

  public withdraw(amount: number) {
    return this._withdraw(amount);
  }

  private async _withdraw(amount: number) {
    try {
      const to = process.env.NEXT_PUBLIC_ADMIN_WALLET_ADDRESS;
      if (!to) {
        return {
          error: 'missing receiver wallet address',
        };
      }
      const signer = await this._getMetamaskSigner();
      const parsedAmount = ethers.utils.parseUnits(amount.toString(), 18);

      const unsignedTx =
        await this.ozoneTokenContract.populateTransaction.transfer(
          to,
          parsedAmount,
          {
            gasLimit: this.gasLimit,
          }
        );

      const signedTx = await signer.sendTransaction(unsignedTx);
      console.log('signed TX');
      const receipt = await signedTx.wait();
      return {
        receipt,
      };
    } catch (err) {
      console.log(err);
      return {
        error: 'some error ocurred',
      };
    }
  }

  public async baseTokenBalance(account: string) {
    return await this._baseTokenBalance(account);
  }

  private async _baseTokenBalance(account: string) {
    try {
      const balance = await this.ozoneTokenContract.balanceOf(account);
      const receipt = ethers.utils.formatEther(balance);

      return {
        receipt,
      };
    } catch (err) {
      console.log(err);
      return {
        error: 'some error ocurred',
      };
    }
  }

  async _getMetamaskSigner() {
    const metamask = new ethers.providers.Web3Provider(window.ethereum);
    await metamask.send('eth_requestAccounts', []);
    return metamask.getSigner();
  }

  _parseBalance(balance: any): string {
    return ethers.utils.formatEther(parseInt(balance._hex).toString());
  }
}

const service = new bnbStakingService();
export default service;
